import { CASHBILL_STATUS_TABLE } from '@/types/api/medical';
import styled from 'styled-components';
import { comma } from '@/utils/ktp';
import { format } from 'date-fns';

type Props = {
  column: { type: string; title: string }[];
  row: any[];
  onOpenModal: (e: any, payload: any) => void;
};

function DataTable({ column, row, onOpenModal }: Props) {
  const getStatus = (status: CASHBILL_STATUS_TABLE) => {
    if (status === 'TRANSFERRED') {
      return '전송완료';
    } else if (status === 'TRANSFER_IN_PROGRESS') {
      return '전송중';
    } else if (status === 'SAVED') {
      return '발급완료';
    } else if (status === 'NOT_ISSUED') {
      return '미발급';
    } else if (status === 'CANCELED') {
      return '취소';
    }
    return '';
  };

  const getStatusColor = (status: CASHBILL_STATUS_TABLE) => {
    if (status === 'TRANSFERRED') {
      return '#A7A7A7';
    } else if (status === 'CANCELED' || status === 'NOT_ISSUED') {
      return '#ED0828';
    }
    return '';
  };

  return (
    <Table>
      <TableHead>
        <TableHeadRow role='row'>
          {column.map(({ type, title }) => (
            <TableHeader
              key={type}
              role='columnheader'
              scope='col'
              width={type === 'id' ? '150px' : 'auto'}
            >
              <Header>
                <HeaderLabel>{title}</HeaderLabel>
              </Header>
            </TableHeader>
          ))}
        </TableHeadRow>
      </TableHead>
      <tbody>
        {row.map((data) => (
          <Row
            role='row'
            key={data.id}
            onClick={(e) =>
              onOpenModal(e, {
                refundIndex: data.refundId,
              })
            }
            tabIndex={0}
          >
            {column.map(({ type }) => (
              <TableData key={type}>
                <Data
                  statusColor={
                    type === 'cashBillStatus' ? getStatusColor(data[type]) : ''
                  }
                >
                  {type === 'cashBillStatus'
                    ? getStatus(data[type])
                    : type === 'localDateTime'
                    ? format(new Date(data[type]), 'yyyy-MM-dd HH:mm')
                    : type === 'refund'
                    ? `${comma(data[type])}원`
                    : data[type]}
                </Data>
              </TableData>
            ))}
          </Row>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  background-color: #ffffff;
  color: #030303;
  border-collapse: collapse;
  margin-bottom: 50px;
`;

const TableHeadRow = styled.tr`
  height: 48px;
  border-bottom: 1px solid #cbccce;
  background-color: #e5e6e8;
`;

const TableHead = styled.thead``;

const TableHeader = styled.th<{ width: string }>`
  padding: 0 20px;
  width: ${({ width }) => width};
  min-width: 150px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030303;
`;

const HeaderLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const Row = styled.tr`
  height: 48px;
  border-bottom: 1px solid #f5f6f7;
  cursor: pointer;

  &:hover {
    background-color: #f7f9fc;
  }
`;

const TableData = styled.td`
  padding: 0 20px;
`;

const Data = styled.span<{ statusColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  ${({ statusColor }) => statusColor && `color: ${statusColor}`}
`;

export default DataTable;
