import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Container } from '@material-ui/core';
import { getRefundList, getRefundSearchKeywords } from '@api/ktp';
import CheckboxForm from '@components/common/CheckboxForm';
import DateSelector from '@components/common/DateSelector';
import RefundStatusList from '@components/ktp/refund-status/RefundStatusList';
import Title from '@components/common/Title';
import useRefundReducer from '@hooks/ktp/useRefundReducer';
import { refundStatusData } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';
import FlexWrap from '@/components/common/FlexWrap';
import DetailModal from '@/components/ktp/refund-status/DetailModal';
import PreviewModal from '@/components/ktp/refund-status/PreviewModal';
import CancelModal from '@/components/ktp/refund-status/CancelModal';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import Pagination from '@/components/common/Pagination';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import DetailSearch from '@/components/common/DetailSearch';
import SearchTooltip from '@/components/common/SearchTooltip';

function RefundStatus() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const keywordType =
    (searchParams.get('searchType') as
      | 'PHONE'
      | 'REFUND'
      | 'CUSTOMER'
      | 'STORE') || '';
  const initialSize = searchParams.get('size') || '10';
  const [state, dispatch] = useRefundReducer();
  const { toggle, selected, filter, dateSelector } = state;
  const { startDate, endDate } = filter;
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const {
    allColumns,
    immediateColumns,
    postColumns,
    departureStatusRadio,
    paymentStatusRadio,
    immediatePaymentStatusRadio,
    refundStatusRadio,
    uploadImageRadio,
  } = refundStatusData;

  // 다음날 날짜 초기화
  useEffect(() => {
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    const currDate = '20' + endDate.replace(/\//g, '-');

    if (currDate !== todayDate) {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate,
          endDate: format(new Date(), 'yyyy-MM-dd'),
        },
      });
    }
  }, []);

  const { isLoading, data } = useQuery(
    ['refundList', filter, currentPage, keyword, initialSize],
    () => getRefundList(filter, currentPage, keyword, keywordType, initialSize),
    {
      enabled: Boolean(filter.startDate),
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );

  const { data: keywords } = useQuery('keywords', getRefundSearchKeywords, {
    retry: false,
  });

  const onFilterRefundType = (type: 'ALL' | 'IMMEDIATE' | 'AFTER') => {
    dispatch({
      type: 'SET_FILTER_REFUND_TYPE',
      payload: type,
    });
    dispatch({ type: 'SET_TOGGLE', payload: null });
  };
  const onFilterCheckBox = useCallback(
    (e, key) => {
      dispatch({ type: 'SET_TOGGLE', payload: null });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          key,
          value: e.target.value,
        },
      });
    },
    [dispatch],
  );
  const onOpenModal = (payload: any) => {
    dispatch({
      type: 'SET_SELECTED',
      payload,
    });
  };
  const onCloseModal = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };
  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <StyledContainer maxWidth='xl'>
      <Header>
        <Title>환급 현황</Title>
        <SearchTooltip />
      </Header>
      {keywords && <DetailSearch data={keywords} />}
      <FilterContainer margin='0 0 22px' justifyContent='space-between'>
        <FlexWrap gap='8px'>
          <FilterBtn
            active={filter.refundType === 'ALL'}
            onClick={() => onFilterRefundType('ALL')}
          >
            전체
          </FilterBtn>
          <FilterBtn
            active={filter.refundType === 'IMMEDIATE'}
            onClick={() => onFilterRefundType('IMMEDIATE')}
          >
            즉시
          </FilterBtn>
          <FilterBtn
            active={filter.refundType === 'AFTER'}
            onClick={() => onFilterRefundType('AFTER')}
          >
            사후
          </FilterBtn>
        </FlexWrap>
        <FlexWrap justifyContent='flex-end' alignItems='center' gap='10px'>
          <ViewDataSelection
            options={[10, 100, 500, 10000]}
            initialSize={initialSize}
          />
          <DateSelector dateSelector={dateSelector} dispatch={dispatch} />
        </FlexWrap>
      </FilterContainer>
      <DataTable
        columns={
          filter.refundType === 'ALL'
            ? allColumns
            : filter.refundType === 'AFTER'
            ? postColumns
            : immediateColumns
        }
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          data ? (
            <RefundStatusList
              refundType={filter.refundType}
              refunds={data.content || []}
              onOpenModal={onOpenModal}
            />
          ) : (
            <p>환급 현황이 없습니다.</p>
          )
        }
      />
      {toggle && (
        <CheckboxForm
          valueArray={
            toggle === 'refundStatus'
              ? refundStatusRadio
              : toggle === 'paymentStatus' && filter.refundType === 'IMMEDIATE'
              ? immediatePaymentStatusRadio
              : toggle === 'paymentStatus'
              ? paymentStatusRadio
              : toggle === 'refundUploadS3Path'
              ? uploadImageRadio
              : departureStatusRadio
          }
          defaultValue={
            toggle === 'refundUploadS3Path'
              ? filter['receiptUploadStatus']
              : filter[toggle]
          }
          type={toggle}
          onFilter={(e) =>
            onFilterCheckBox(
              e,
              toggle === 'refundUploadS3Path' ? 'receiptUploadStatus' : toggle,
            )
          }
          style={{
            top: 330,
            right:
              filter.refundType === 'ALL'
                ? 300
                : filter.refundType === 'AFTER'
                ? 290
                : 60,
          }}
        />
      )}
      <BottomSection>
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
      {selected?.refundIndex && !selected?.totalRefund && (
        <DetailModal onClose={onCloseModal} selected={selected} />
      )}
      {selected?.imgUrl && (
        <PreviewModal onClose={onCloseModal} selected={selected} />
      )}
      {selected?.totalRefund && (
        <CancelModal onClose={onCloseModal} selected={selected} />
      )}
    </StyledContainer>
  );
}
const FilterContainer = styled(FlexWrap)`
  position: relative;
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;
const FilterBtn = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#354EFF' : '#FFFFFF')};
  color: ${(props) => (props.active ? '#FFFFFF' : '#757575')};
  border: 1px solid ${(props) => (props.active ? '#354EFF' : '#757575')};
  font-size: 14px;
  line-height: 22px;
  padding: 4px 22px;
  border-radius: 100px;
  :hover {
    opacity: 0.6;
  }
`;
const BottomSection = styled.div`
  margin: 20px 0;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

export default RefundStatus;
