import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from '@/pages/medical/Home';
import HospitalNotice from '@/pages/medical/HospitalNotice';
import HospitalRefundStatus from '@/pages/medical/HospitalRefundStatus';
import HospitalStatus from '@/pages/medical/HospitalStatus';
import CommonLayout from '@components/common/CommonLayout';
import Prepare from '@pages/common/Prepare';
import SignIn from '@pages/common/SignIn';
import ControlCode from '@pages/developer/ControlCode';
import Jasypt from '@pages/developer/Jasypt';
import HostApplicant from '@pages/etc/noonch/HostApplicant';
import Statistics from '@pages/etc/noonch/Statistics';
import UserInfo from '@pages/etc/noonch/UserInfo';
import SuccessHome from '@pages/etc/successmode/Home';
import FranchiseeStatus from '@pages/ktp/FranchiseeStatus';
import KtpHome from '@pages/ktp/Home';
import Notice from '@pages/ktp/Notice';
import PointWithdrawal from '@pages/ktp/PointWithdrawal';
import PushNotification from '@pages/ktp/PushNotification';
import RefundStatus from '@pages/ktp/RefundStatus';
import RemittanceStatus from '@/pages/ktp/RemittanceStatus';
import HospitalRemittanceStatus from '@/pages/medical/HospitalRemittanceStatus';
import KioskHome from '@/pages/kiosk/Home';
import Redirect from '@/pages/common/Redirect';
import KtpQna from '@/pages/qna/ktp';
import MedicalQna from '@/pages/qna/medical';
import FranchiseeInquiryHistory from '@/pages/sales';
import KtpKioskStatus from '@/pages/kiosk/KtpKioskStatus';
import HospitalBarobill from '@/pages/medical/HospitalBarobill';

const MainRouter = () => {
  return (
    <Router>
      <Redirect />
      <CommonLayout>
        <Switch>
          {/* MAIN */}
          <Route path='/' component={SignIn} exact />
          {/* KTP */}
          <Route path='/ktp/:server/home' component={KtpHome} />
          <Route
            path='/ktp/:server/franchisee-status'
            component={FranchiseeStatus}
          />
          <Route
            path='/ktp/:server/refund-remittance'
            component={RemittanceStatus}
          />
          <Route path='/ktp/:server/refund-status' component={RefundStatus} />
          {/* <Route path="/ktp/:server/kiosk-status" component={KioskStatus} /> */}
          <Route path='/ktp/:server/notice' component={Notice} />
          <Route
            path='/ktp/:server/push-notification'
            component={PushNotification}
          />
          <Route
            path='/ktp/:server/point-withdrawal'
            component={PointWithdrawal}
          />
          {/* Medical */}
          <Route path='/refund/medical/:server/home' component={Home} />
          <Route
            path='/refund/medical/:server/franchisee-status'
            component={HospitalStatus}
          />
          <Route
            path='/refund/medical/:server/refund-status'
            component={HospitalRefundStatus}
          />
          <Route
            path='/refund/medical/:server/refund-remittance'
            component={HospitalRemittanceStatus}
          />
          {/* <Route
            path="/refund/medical/:server/kiosk-status"
            component={HospitalKioskStatus}
          /> */}
          <Route
            path='/refund/medical/:server/notice'
            component={HospitalNotice}
          />
          <Route
            path='/refund/medical/:server/barobill'
            component={HospitalBarobill}
          />
          <Route path='/refund/medical/:server' component={Prepare} />
          {/* Developer */}
          <Route path='/developer/jasypt' component={Jasypt} />
          <Route path='/developer/refund' component={ControlCode} />
          <Route path='/developer/refund-test' component={ControlCode} />
          {/* Sales */}
          <Route
            path='/sales/:server/history'
            component={FranchiseeInquiryHistory}
          />
          {/* 키오스크 */}
          <Route path='/kiosk/:server/home' component={KioskHome} />
          <Route
            path='/kiosk/:server/franchisee-status'
            component={KtpKioskStatus}
          />
          {/* Qna */}
          <Route path='/qna/:server/ktp' component={KtpQna} />
          <Route path='/qna/:server/medical' component={MedicalQna} />
          {/* SUCCESSMODE */}
          <Route path='/etc/successmode' component={SuccessHome} />
          <Route path='/etc/noonch/host-applicant' component={HostApplicant} />
          <Route path='/etc/noonch/user-info' component={UserInfo} />
          <Route path='/etc/noonch/statistics' component={Statistics} />
          <Route path='/etc/modugrim' component={Prepare} />
          <Route path='/etc/hotel/:server' component={Prepare} />
        </Switch>
      </CommonLayout>
    </Router>
  );
};
export default MainRouter;
